import firebase from 'firebase/app';
import 'firebase/auth';
import IFirebaseConfig from '../interfaces/IFirebaseConfig';

export const getFirebaseSettings = (): IFirebaseConfig => {
  let FIREBASE_DOMAIN = '';
  let FIREBASE_API_KEY = '';
  if (window._env_) {
    FIREBASE_DOMAIN = window._env_.FIREBASE_DOMAIN;
    FIREBASE_API_KEY = window._env_.FIREBASE_API_KEY;
  } else {
    FIREBASE_DOMAIN = process.env.REACT_APP_FIREBASE_DOMAIN || '';
    FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || '';
  }

  if (FIREBASE_API_KEY && FIREBASE_DOMAIN) {
    return {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_DOMAIN,
    };
  }
  return {
    apiKey: '',
    authDomain: '',
  };
};

const Firebase = !firebase.apps.length ? firebase.initializeApp(getFirebaseSettings()) : firebase.app();

export const auth = Firebase.auth();
export default Firebase;
