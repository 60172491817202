import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import IServerResponse from '../interfaces/serviceResponses/IServerResponse';
import { getAccessToken } from './localStorage';

export default (baseUrl?: string, requestConfig?: AxiosRequestConfig): AxiosInstance => {
  const apiUrl = baseUrl ? baseUrl : window._env_ ? window._env_.API_URL : process.env.REACT_APP_API_URL;
  const defaultConfig = {
    ...requestConfig,
  };

  if (apiUrl) {
    defaultConfig.baseURL = apiUrl;
  }

  const instance = axios.create(defaultConfig);

  instance.interceptors.request.use(config => {
    if (!config.headers || !config.headers.Authorization) {
      const token = getAccessToken();
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return config;
  });

  return instance;
};
//   instance.interceptors.request.use(config => {
//     if (config.headers) config.headers['Authorization'] = getAccessToken();
//     return config;
//   });
//   return instance;
// };

export const getErrorMessage = (response: IServerResponse): string => {
  if (response && response.error) {
    response.error;
  }

  if (response && response.errors.length) {
    response.errors[0];
  }

  return 'Falha na conexão, tente novamente mais tarde';
};
