import React from 'react';
import { Route } from 'react-router-dom';

interface IProps {
  component: React.FC | React.ElementType;
}

const PublicRoute = ({ component: Component, ...rest }: IProps): JSX.Element => {
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PublicRoute;
