import { getDashboardResponse } from '../interfaces/IDashboard';
import { getAccessToken } from './localStorage';
import { getLanguage } from '../utils/getLanguage';

import http from './http';

const { post } = http();

export const getDashboard = async (dashboardName: string[]): Promise<getDashboardResponse> => {
  return new Promise<getDashboardResponse>(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('looker_access_token');

      const response = await post(
        '/api/v1/looker/dashboard_url/',
        { dashboards: dashboardName, language: getLanguage() },
        {
          headers: { Authorization: token },
        }
      );
      resolve(response.data);
    } catch (error) {
      localStorage.setItem('looker_access_token', '');
      localStorage.removeItem('selected_customer_name');
      reject(error);
    }
  });
};
