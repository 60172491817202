import styled from 'styled-components';

export const Container = styled.div`
  height: 60px;
  display: flex;
  background-color: #d9d9d94d;
  color: #666666;
`;

export const UserName = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
`;

export const UserMail = styled.span`
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;
