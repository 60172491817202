import Public from '../pages/Public';
import MonthlyConsumption from '../pages/ConsumoMensal';
import Budget from '../pages/Orcamento';
import Details from '../pages/Detalhes';
import Recommendations from '../pages/Recomendacoes';
import ConsumptionAnalysis from '../pages/AnaliseConsumo';
import Clientes from '../pages/Clientes';
import Grupos from '../pages/Grupos';
import AnoFiscal from '../pages/AnoFiscal';
import AddAnoFiscal from '../pages/AnoFiscal/add';
import EditAnoFiscal from '../pages/AnoFiscal/edit';
//ver as classes de Grupos importado

const pages = [
  {
    exact: true,
    path: '/',
    auth: false,
    component: Public,
  },
  {
    exact: true,
    path: '/consumo_mensal',
    auth: true,
    component: MonthlyConsumption,
  },
  {
    exact: true,
    path: '/orcamento',
    auth: true,
    component: Budget,
  },
  {
    exact: true,
    path: '/detalhes',
    auth: true,
    component: Details,
  },
  {
    exact: true,
    path: '/analise_consumo',
    auth: true,
    component: ConsumptionAnalysis,
  },
  {
    exact: true,
    path: '/recomendacoes',
    auth: true,
    component: Recommendations,
  },
  {
    exact: true,
    path: '/grupos',
    auth: true,
    component: Grupos,
  },
  {
    exact: true,
    path: '/clientes',
    auth: true,
    component: Clientes,
  },
  {
    exact: true,
    path: '/anofiscal',
    auth: true,
    component: AnoFiscal,
  },
  {
    exact: true,
    path: '/anofiscal/add',
    auth: true,
    component: AddAnoFiscal,
  },
  {
    exact: true,
    path: '/anofiscal/edit',
    auth: true,
    component: EditAnoFiscal,
  },
];

export default pages;
