import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 16px;
  font-weight: 600;
  color: #666666;
  padding-left: 18px;
  padding-right: 15px;
  height: 50px;
  cursor: pointer;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 10px;

  &:hover {
    background-color: #237458;
    color: #ffffff;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}`;
