import React from 'react';

const fiscalYearCalendar = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.25 8.25C3.83579 8.25 3.5 8.58579 3.5 9C3.5 9.41421 3.83579 9.75 4.25 9.75H5.25C5.66421 9.75 6 9.41421 6 9C6 8.58579 5.66421 8.25 5.25 8.25H4.25Z" fill="#666666" />
      <path d="M3.5 12.5C3.5 12.0858 3.83579 11.75 4.25 11.75H5.25C5.66421 11.75 6 12.0858 6 12.5C6 12.9142 5.66421 13.25 5.25 13.25H4.25C3.83579 13.25 3.5 12.9142 3.5 12.5Z" fill="#666666" />
      <path d="M4.25 15.25C3.83579 15.25 3.5 15.5858 3.5 16C3.5 16.4142 3.83579 16.75 4.25 16.75H5.25C5.66421 16.75 6 16.4142 6 16C6 15.5858 5.66421 15.25 5.25 15.25H4.25Z" fill="#666666" />
      <path d="M7 9C7 8.58579 7.33579 8.25 7.75 8.25H8.75C9.16421 8.25 9.5 8.58579 9.5 9C9.5 9.41421 9.16421 9.75 8.75 9.75H7.75C7.33579 9.75 7 9.41421 7 9Z" fill="#666666" />
      <path d="M11.25 8.25C10.8358 8.25 10.5 8.58579 10.5 9C10.5 9.41421 10.8358 9.75 11.25 9.75H12.25C12.6642 9.75 13 9.41421 13 9C13 8.58579 12.6642 8.25 12.25 8.25H11.25Z" fill="#666666" />
      <path d="M14 9C14 8.58579 14.3358 8.25 14.75 8.25H15.75C16.1642 8.25 16.5 8.58579 16.5 9C16.5 9.41421 16.1642 9.75 15.75 9.75H14.75C14.3358 9.75 14 9.41421 14 9Z" fill="#666666" />
      <path d="M11.25 11.75C10.8358 11.75 10.5 12.0858 10.5 12.5C10.5 12.9142 10.8358 13.25 11.25 13.25H12.25C12.6642 13.25 13 12.9142 13 12.5C13 12.0858 12.6642 11.75 12.25 11.75H11.25Z" fill="#666666" />
      <path d="M7 12.5C7 12.0858 7.33579 11.75 7.75 11.75H8.75C9.16421 11.75 9.5 12.0858 9.5 12.5C9.5 12.9142 9.16421 13.25 8.75 13.25H7.75C7.33579 13.25 7 12.9142 7 12.5Z" fill="#666666" />
      <path d="M11.25 15.25C10.8358 15.25 10.5 15.5858 10.5 16C10.5 16.4142 10.8358 16.75 11.25 16.75H12.25C12.6642 16.75 13 16.4142 13 16C13 15.5858 12.6642 15.25 12.25 15.25H11.25Z" fill="#666666" />
      <path d="M14 12.5C14 12.0858 14.3358 11.75 14.75 11.75H15.75C16.1642 11.75 16.5 12.0858 16.5 12.5C16.5 12.9142 16.1642 13.25 15.75 13.25H14.75C14.3358 13.25 14 12.9142 14 12.5Z" fill="#666666" />
      <path d="M7.75 15.25C7.33579 15.25 7 15.5858 7 16C7 16.4142 7.33579 16.75 7.75 16.75H8.75C9.16421 16.75 9.5 16.4142 9.5 16C9.5 15.5858 9.16421 15.25 8.75 15.25H7.75Z" fill="#666666" />
      <path d="M14 16C14 15.5858 14.3358 15.25 14.75 15.25H15.75C16.1642 15.25 16.5 15.5858 16.5 16C16.5 16.4142 16.1642 16.75 15.75 16.75H14.75C14.3358 16.75 14 16.4142 14 16Z" fill="#666666" />
      <path d="M4.25 5.25C3.83579 5.25 3.5 5.58579 3.5 6C3.5 6.41421 3.83579 6.75 4.25 6.75H15.75C16.1642 6.75 16.5 6.41421 16.5 6C16.5 5.58579 16.1642 5.25 15.75 5.25H4.25Z" fill="#666666" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 2H4V1C4 0.447716 4.44771 0 5 0C5.55228 0 6 0.447716 6 1V2L14 2V0.999999C14 0.447715 14.4477 0 15 0C15.5523 0 16 0.447715 16 0.999999V2H17C18.6569 2 20 3.34314 20 5V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V5C0 3.34314 1.34315 2 3 2ZM3 18C2.44772 18 2 17.5523 2 17V5C2 4.44771 2.44771 4 3 4L17 4C17.5523 4 18 4.44771 18 5V17C18 17.5523 17.5523 18 17 18H3Z" fill="#666666" />
    </svg>
  )
}

export default fiscalYearCalendar;
