import React, { useState, useRef, useEffect } from 'react';
import { Container, UserMail, UserName } from './styles';
import { FaRegQuestionCircle, FaBars } from 'react-icons/fa';
import Union from '../../../assets/images/Union.png';
import Sidebar from '../Sidebar';
import { getAccessToken, getLocalUser } from '../../services/localStorage';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const user = getLocalUser();
  const ref = useRef<any>();
  const location = useLocation();
  const accessToken = getAccessToken();
  const selectedCustomer = localStorage.getItem('selected_customer_name');

  const titleCustomer = selectedCustomer
    ? ((user.email.match(/@(.+?)\./)?.[1] || '').split('.')[0]?.toUpperCase() || '') + ' (' + selectedCustomer + ')'
    : (user.email.match(/@(.+?)\./)?.[1] || '').split('.')[0]?.toUpperCase() || '';

  // #recupera o token do usuário logado
  if (!accessToken || location.pathname === '/logout') {
    return null; // Não renderizar o Header se o token de acesso não existir ou após o logout
  }

  return (
    <Container>
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ paddingLeft: 100 }}>
          <span style={{ color: '#666666', fontFamily: 'Source Sans Pro', fontWeight: 700, fontSize: 20 }}>
            CLOUD BILLING DASHBOARD{'\n'}
          </span>
          <span style={{ color: '#1C9D5B', fontFamily: 'Source Sans Pro', fontWeight: 700, fontSize: 20 }}>
            {titleCustomer}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-end',
            paddingRight: '10px',
          }}>
          <div>
            <UserName>{user.profile.name}</UserName>
          </div>
          <div>
            <UserMail>{user.email}</UserMail>
          </div>
        </div>
      </div>
      <div style={{ padding: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FaRegQuestionCircle style={{ width: '20px', height: '20px' }} />
      </div>

      {<Sidebar ref={ref} />}
    </Container>
  );
};

export default Header;
