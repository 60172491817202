import styled from 'styled-components';

export const Container = styled.div`
  z-index: 999;
  background-color: #f5f5f5;
  position: fixed;
  height: 100%;
  top: 0px;
  width: 250px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  left: ${(props: any) => (props.sidebar ? '0' : '-100%')};
`;

export const ContainerClosed = styled.div`
  z-index: 999;
  background-color: #f5f5f5;
  position: fixed;
  height: 100%;
  top: 0px;
  left: 0px;
  width: 60px;
`;

export const Content = styled.div`
  margin-top: 20px;
`;
