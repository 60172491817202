import React, { useEffect, useState } from 'react';
import { getDashboard } from '../../services/dashboard';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { FaCreativeCommonsNc } from 'react-icons/fa';

// ...

function MonthlyConsumption(): JSX.Element {
  const [dashboardURL, setDashboardURL] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [redirectTimeout, setRedirectTimeout] = useState<NodeJS.Timeout | null>(null); // Alteração aqui
  const history = useHistory();

  useEffect(() => {
    const getDashboardURL = async () => {
      try {
        const response = await getDashboard(['consumo_mensal_menu']);
        setDashboardURL(response.data[0].consumo_mensal_menu);
      } catch (error) {
        setErrorModalOpen(true);
        const timeoutId = setTimeout(() => {
          history.push('/clientes');
        }, 8000);
        setRedirectTimeout(timeoutId);
      }
    };
    getDashboardURL();

    return () => {
      if (redirectTimeout) {
        clearTimeout(redirectTimeout);
      }
    };
  }, []);

  const handleModalOk = () => {
    setErrorModalOpen(false);
    if (redirectTimeout) {
      clearTimeout(redirectTimeout);
    }
    history.push('/clientes');
  };

  // ...

  return (
    <>
      <div className="main-content">
        <iframe src={dashboardURL} />
      </div>

      <Modal
        isOpen={errorModalOpen}
        onRequestClose={() => setErrorModalOpen(false)}
        portalClassName="custom-modal-portal"
        className="custom-modal">
        <h2>
          CLIENTE INACESSÍVEL <FaCreativeCommonsNc />{' '}
        </h2>
        <p>
          Ops! Encontramos algum problema ao acessar o cliente <b>{localStorage.getItem('selected_customer_name')}</b>.
        </p>
        <p>
          Entre em contato com <b>cx@ipnet.cloud</b> em caso de dúvida.
        </p>
        <button onClick={handleModalOk}>OK</button>
      </Modal>
    </>
  );
}

export default MonthlyConsumption;
