import Swal from 'sweetalert2'

export class AlertModal {
  public success(message: string) {
    return Swal.fire(`${message}`, '', 'success')
  }

  public error(message: string, subMessage) {
    return Swal.fire(`${message}`, `${subMessage}`, 'error')
  }
}
