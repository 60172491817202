import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next) /** inicializa a biblioteca */
  .use(LanguageDetector) /** detecta o idioma do navegador do usuário */
  .use(backend) /** permite leitura dos arquivos no diretório public/locales ou de uma aplicação externa */
  .init({
    lng: 'pt' /** idioma utilizado caso o idioma detectado não seja suportado */,
    fallbackLng: 'pt' /** idioma padrão */,
    supportedLngs: ['pt-BR', 'es-LATAM', 'en-US'] /** idiomas suportados */,
    debug: false /** habilita modo de debug */,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json' /** caminho dos arquivos de tradução */,
    },
  });

export default i18n;
