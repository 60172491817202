import { useHistory } from 'react-router-dom';
import React from 'react';
//importar imagem em assets
import Icon from '../../assets/images/Buscar.png';
//importar estilos
import './grupos.css';
import { useEffect, useState } from 'react';
import { FaAddressCard } from 'react-icons/fa';
import Modal from 'react-modal';

const Grupos = () => {
  const history = useHistory();
  const [gruposFiltrados, setGruposFiltrados] = useState([]);
  const listaNomesGrupos = ['Grupo A', 'Grupo B', 'Grupo C'];
  const [modalVisible, setModalVisible] = useState(false);

  const handleFilterChange = event => {
    const grupoFiltro = event.target.value.toLowerCase();
    const gruposFiltrados = listaNomesGrupos.filter(grupo => grupo.toLowerCase().includes(grupoFiltro));
    setGruposFiltrados(gruposFiltrados);
  };
  const openModal = () => {
    setModalVisible(true);
  };
  // Função para fechar o modal
  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="main">
      <div className="header">
        <h3>Grupos</h3>
        <div className="search-box">
          <input type="text" onChange={handleFilterChange} placeholder="Buscar cliente" />
          <button>
            <img className="search-icon" src={Icon} alt="Ícone de busca" />
          </button>
        </div>
        <button className="new-group-button">NOVO GRUPO</button>
      </div>

      <div className="box-empresa-list">
        <div className="empresa-list">
          {/* Mapeia os grupos filtrados e suas divs */}
          {listaNomesGrupos.map((grupo, index) => (
            <div key={index} className="empresa-item">
              <div className="group-box">
                <span className="group-name">{grupo}</span>
                <div className="button-container">
                  <button className="edit-button" onClick={openModal}>
                    Editar
                  </button>
                  <button className="delete-button" onClick={openModal}>
                    Excluir
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        portalClassName="custom-modal-portal"
        className="custom-modal">
        <h2>NOVO GRUPO</h2>
        <button onClick={closeModal}>OK</button>
        <button onClick={closeModal}>CANCELAR</button>
      </Modal>
    </div>
  );
};
export default Grupos;
