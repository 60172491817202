import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './anofiscal.css';
import { Box } from '@material-ui/core';
import { BackendService } from '../../services/backend-service';
import { getOnlyValueNumbers } from '../../utils/fiscalYear-utils';
import CurrencyInput from 'react-currency-input';
import { useLocation } from 'react-router';
import { AlertModal } from '../../utils/modal-alerts';

const EditAnoFiscal = () => {
  const alert = new AlertModal();
  const backendService = new BackendService();
  const history = useHistory();
  const [dataSubmit, setDataSubmit]: any = useState({});
  const [currentFiscalYear, setCurrentFiscalYear]: any = useState({ fiscalYear: '', monthData: [{}] });
  const location: any = useLocation();

  useEffect(() => {
    getCurrentFiscalYear();
  }, [])

  async function getCurrentFiscalYear() {
    const year = location.state.fiscalYear;
    const currentFiscalYear: any[] = await backendService.getFiscalYearByYear(year);
    setCurrentFiscalYear(currentFiscalYear);
    console.log('setCurrentFiscalYear', currentFiscalYear);
  }

  function handleChange(event, item) {
    const name = event.target.name;
    const value = event.target.value
    item.value = value

    setDataSubmit((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  async function submit() {
    const dataMapped = currentFiscalYear.monthData.map((item) => {
      return {
        month: item.month,
        value: parseFloat(item.value) ? item.value : getOnlyValueNumbers(item.value)
      }
    });

    const submitData = {
      year: location.state.fiscalYear,
      data: dataMapped
    };

    try {
      await backendService.updateFiscalYear(submitData);
      history.push('/anofiscal');
      alert.success('Ano fiscal criado com sucesso !');
    } catch (err) {
      alert.error('Erro ao editar ano fiscal !', 'Revise os dados e tente novamente');
    }
    // TODO - ADD AN NOTIFICATION BOX
    return;
  }

  function createTableContent() {
    const monthsData = currentFiscalYear.monthData;

    if (!monthsData) {
      return (<div></div>)
    }

    return (
      monthsData.map((item, index) => {
        return (
          <Box flexBasis={{ xs: '100%', sm: '50%', md: '20%' }} p={1} key={index}>
            <label className="custom-label">
              {item.monthName}
              <br />
              <CurrencyInput
                className="custom-input"
                value={item.value}
                allowNegative={false}
                prefix={'R$'}
                suffix={''}
                maxLength={16}
                precision={2}
                decimalSeparator={','}
                thousandSeparator={'.'}
                name={`${index}`}
                onChangeEvent={e => handleChange(e, item)}
                style={{ width: '100%' }}
              />
            </label>
          </Box>
        )
      })
    )
  }

  return (
    <div className="main">
      <div className="header">
        <h3>Ano Fiscal - Editar</h3>
        <div className="new-button">
        </div>
      </div>

      <div className="table-content">
        <Box display="flex" flexWrap="wrap">
          <Box width={120} p={1}>
            <label className="custom-label">
              Ano Fiscal
              <br />
              <input className="custom-input" type="text" value={currentFiscalYear?.fiscalYear || ''} disabled />
            </label>
          </Box>
          <Box width={200} p={1}>
            <label className="custom-label">
              Início
              <br />
              <input className="custom-input" type="text" value="2023-01-01" disabled />
            </label>
          </Box>
          <Box width={200} p={1}>
            <label className="custom-label">
              Fim
              <br />
              <input className="custom-input" type="text" value="2023-12-31" disabled />
            </label>
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {createTableContent()}
        </Box>

      </div>

      <div className="new-button">
        <button className="button-cancel" onClick={() => history.push('/anofiscal')}>
          CANCELAR
        </button>
        <button className="button-active" onClick={submit}>SALVAR</button>
      </div>
    </div>
  );
};
export default EditAnoFiscal;
