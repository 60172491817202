import React, { useEffect, useState } from 'react';
import GoogleLogin from './GoogleLogin';
import IpnetFooterLogo from '../../assets/images/ipnet_footer_logo.svg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Public = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [country, setCountry] = useState('');
  const slug = useParams<{ slug: string }>();

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country);
      })
      .catch(error => console.log('Erro ao buscar dados de localização', error));

    switch (country) {
      case 'BR':
        i18n.changeLanguage('pt-BR');
        break;
      case 'US':
        i18n.changeLanguage('en-US');
        break;
      default:
        i18n.changeLanguage('es-LATAM');
    }
  }, [country, i18n]);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Lógica para lidar com o envio do formulário
  };

  return (
    <section className="page-section login-page">
      <h1>{country}</h1>
      <div className="page-content login-card">
        <h1>{t('login-page.title')}</h1>
        <p>{t('login-page.subtitle')}</p>
        <GoogleLogin language={country} />
      </div>
      <footer className="page-footer">
        <img
          src={IpnetFooterLogo}
          alt="Ipnet logo, o nome ipnet escrito em letras pretas com o subtitulo 'growth partner'"
        />
      </footer>
    </section>
  );
};

export default Public;
