import { createMonthData } from "../utils/fiscalYear-utils";
import http from "./http";
import { ICreateFiscalYearRequest, IUpdateFiscalYearRequest } from "./interfaces/dashboardRequests";
const { post, get, put } = http();

export class BackendService {
  private token: string

  constructor() {
    this.token = localStorage.getItem('looker_access_token');
  }


  public async getAllFiscalYear(): Promise<any[]> {
    //TODO -- CREATE PROCESS TO PAGINATE
    //TODO - VERIFY IF THE GET HAS ERRORS
    const url = '/api/v1/fiscal_year'

    try {
      const response = await get(url, { headers: this.setHeader() });
      const { data } = response.data;
      const { items } = data;
      return items
    } catch (err) {
      console.error(err)
    }

    return []
  }

  public async getFiscalYearByYear(year: number): Promise<any> {
    //TODO - VERIFY IF THE GET HAS ERRORS
    const url = `/api/v1/fiscal_year/${year}`

    try {
      const response = await get(url, { headers: this.setHeader() });
      const { data } = response.data
      const monthData = createMonthData(data);

      const output = {
        fiscalYear: year,
        monthData
      }

      return output;

    } catch (err) {
      console.error(err)
    }
  }


  public async createFiscalYear(data: ICreateFiscalYearRequest): Promise<any> {
    const url = '/api/v1/fiscal_year/';

    try {
      await post(url, data, { headers: this.setHeader() });
    } catch (err) {
      console.error(err);
    }

    return;
  }

  public async updateFiscalYear(data: IUpdateFiscalYearRequest) {
    const url = '/api/v1/fiscal_year/';

    try {
      await put(url, data, { headers: this.setHeader() });
    } catch (err) {
      console.error(err);
    }

    return;
  }


  private setHeader() {
    return {
      Authorization: this.token
    };
  }

}
