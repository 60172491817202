export const fiscalYearLabels = {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
};

export const getOnlyValueNumbers = (stringValue: string) => {
  if (!stringValue) {
    return stringValue;
  }

  const onlyNumbers = stringValue.match(/[\d,]/g);
  const value = onlyNumbers.reduce((acc, item) => {
    return acc + item
  }, '');

  const valueReplaced = parseFloat(value.replaceAll(".", "").replace(',', "."));

  return valueReplaced
}


export const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


export function groupDataByFiscalYear(fiscalYear) {
  const data = fiscalYear;
  const grouped = groupBy(data, "year");
  return grouped
}


export function createListRows(data) {
  const fiscalYearList = [];

  Object.keys(data).forEach(function (key, index) {
    const yearDatas = data[key];

    const resultMapped = yearDatas.map((item, index) => item.value);
    fiscalYearList.push({
      anoFiscal: key,
      janeiro: resultMapped[0] || '',
      fevereiro: resultMapped[1] || '',
      marco: resultMapped[2] || '',
      abril: resultMapped[3] || '',
      maio: resultMapped[4] || '',
      junho: resultMapped[5] || '',
      julho: resultMapped[6] || '',
      agosto: resultMapped[7] || '',
      setembro: resultMapped[8] || '',
      outubro: resultMapped[9] || '',
      novembro: resultMapped[10] || '',
      dezembro: resultMapped[11] || '',
    });
  });

  return fiscalYearList;
}


export const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push({ month: item.month, value: item.value });
    return result
  }, {});
};


export const createMonthData = (yearData: any[]) => {
  const months = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

  const mapped = yearData.map((item) => {
    const splittedDate = (item.month).split('-')
    const month = splittedDate[1];
    item.monthName = months[month - 1]
    return item;
  })

  return mapped
}
