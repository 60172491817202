import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import './anofiscal.css';
import { Box } from '@material-ui/core';
import { BackendService } from '../../services/backend-service';
import { fiscalYearLabels, getOnlyValueNumbers } from '../../utils/fiscalYear-utils';
import CurrencyInput from 'react-currency-input';
import { AlertModal } from '../../utils/modal-alerts';

const AddAnoFiscal = () => {
  const alert = new AlertModal();
  const history = useHistory();
  const backendService = new BackendService();
  const [dataSubmit, setDataSubmit]: any = useState({});
  const [fiscalYear, setFiscalYear]: any = useState();

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    setDataSubmit((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function prepareCreateDataRequest() {
    const monthsMapped = Object.keys(dataSubmit).map((item, index) => {
      const monthValue = dataSubmit[item];
      const indexMonth = index + 1;
      const formatMonth = (indexMonth < 10) ? `${"0" + (indexMonth)}` : indexMonth;

      return {
        "month": `${fiscalYear}-${formatMonth}-01`,
        "value": getOnlyValueNumbers(monthValue)
      }
    })

    const toSubmit = {
      year: fiscalYear,
      data: monthsMapped
    };

    console.log('toSubmit', toSubmit);

    return toSubmit;
  }


  async function submit() {
    const data = prepareCreateDataRequest();

    if (!data) {
      return;
    }

    try {
      await backendService.createFiscalYear(data);
      history.push('/anofiscal');
      alert.success('Ano fiscal criado com sucesso !');
    } catch (err) {
      alert.error('Erro ao criar ano fiscal !', 'Revise os dados e tente novamente');
    }
  }

  return (
    <div className="main">
      <div className="header">
        <h3>Ano Fiscal - NOVO</h3>
      </div>

      <div className="table-content">
        <Box display="flex" flexWrap="wrap">
          <Box width={120} p={1}>
            <label className="custom-label">
              Ano Fiscal
              <br />
              <input
                className="custom-input"
                type="text"
                name="fiscalYear"
                onChange={e => setFiscalYear(e.target.value)}
                required
              />
            </label>S
          </Box>
          <Box width={200} p={1}>
            <label className="custom-label">
              Início
              <br />
              <input
                className="custom-input"
                type="text"
                name="initialDate"
                onChange={handleChange}
              />
            </label>
          </Box>
          <Box width={200} p={1}>
            <label className="custom-label">
              Fim
              <br />
              <input
                className="custom-input"
                type="text"
                name="finalDate"
                onChange={handleChange}
              />
            </label>
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {fiscalYearLabels.months.map((mes, index) => (
            <Box flexBasis={{ xs: '100%', sm: '50%', md: '20%' }} p={1} key={index}>
              <label className="custom-label">
                {mes}
                <br />
                <CurrencyInput
                  className="custom-input"
                  allowNegative={false}
                  type="text"
                  prefix={'R$'}
                  suffix={''}
                  maxLength={16}
                  precision={2}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  name={`${index}`}
                  value={dataSubmit[index]}
                  onChangeEvent={e => handleChange(e)}
                  style={{ width: '100%' }}
                />
              </label>
            </Box>
          ))}
        </Box>
        <div className="new-button">
          <button
            className="button-cancel"
            onClick={() => history.push('/anofiscal')}
          >
            CANCELAR
          </button>
          <button className="button-active" onClick={submit}>SALVAR</button>
        </div>
      </div>
    </div>
  );

};

export default AddAnoFiscal;

