import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppContext from '../common/AppContext';

interface IProps {
  component: React.FC | React.ElementType;
}

const PrivateRoute = ({ component: Component, ...rest }: IProps): JSX.Element => {
  const {
    authState: { isAuth },
  } = useContext(AppContext).appState;

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: '/logout' }} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
