import React, { useState, useEffect } from 'react';
import http, { getErrorMessage } from '../../services/http';
import './clientes.css';
import Icon from '../../assets/images/Buscar.png';
import { useHistory } from 'react-router';
import { getAccessToken } from '../../services/localStorage';

const Clientes = () => {
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const customersFromLocalStorage = JSON.parse(localStorage.getItem('customers'));
    setCustomers(customersFromLocalStorage || []);
  }, []);

  // Filtra as empresas com base no valor de busca
  const clientesFiltrados = customers.filter(cliente =>
    cliente.customer_name.toLowerCase().includes(search.toLowerCase())
  );

  // Função chamada quando o botão de uma empresa é clicado
  const handleButtonClick = async cliente => {
    try {
      localStorage.setItem('selected_customer_id', cliente.customer_id);
      localStorage.setItem('selected_customer_name', cliente.customer_name);
      const response = await http().post('/api/v1/auth/choose_customer', {
        customer_id: cliente.customer_id,
        firebase_token: getAccessToken(),
      });

      localStorage.setItem('looker_access_token', response.data.data.access_token);
      history.push('/consumo_mensal');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="main">
      <div className="header">
        <h3>Clientes</h3>
        <div className="search-box">
          <input type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder="Buscar cliente" />
          <button>
            <img className="search-icon" src={Icon} alt="Ícone de busca" />
          </button>
        </div>
      </div>

      <div className="box-empresa-list">
        <div className="empresa-list">
          {clientesFiltrados.map((cliente, index) => (
            <div key={cliente.customer_id} className="empresa-item">
              <button className="button" onClick={() => handleButtonClick(cliente)}>
                {cliente.customer_name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clientes;
