/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from './styles';
import { IconType } from 'react-icons/lib';
import HomeIcon from '../../assets/images/home.png';

interface ISidebarItem {
  Icon: any;
  Text: string;
  disabled?: boolean;
}

const SidebarItem: React.FC<ISidebarItem> = ({ Icon, Text, disabled }) => {
  return (
    <Container className={disabled ? 'disabled' : ''}>
      <div style={{ width: '23px', height: '23px', marginRight: '20px' }}>
        <Icon style={{ width: '23px', height: '23px' }} />
      </div>
      <span>{Text}</span>
    </Container>
  );
};

export default SidebarItem;
