import React from 'react';

const NotFound = (): JSX.Element => {
  return (
    <main className="page-not-found">
      <section className="page-section">
        <img src="" className="logo" alt="IPNET" />
        <h1>Página não encontrada</h1>
        <footer className="page-footer">
          <a className="App-link" href="https://www.ipnet.cloud/" target="_blank" rel="noopener noreferrer">
            IPNET - Growth Partner
          </a>
        </footer>
      </section>
    </main>
  );
};

export default NotFound;
