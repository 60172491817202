import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import './anofiscal.css';
import { FaRegEdit } from 'react-icons/fa';
import { Box } from '@material-ui/core';
import { BackendService } from '../../services/backend-service';
import { createListRows, currencyFormatter, groupDataByFiscalYear } from '../../utils/fiscalYear-utils';

const columns = [
  { id: 'anoFiscal', label: 'Ano fiscal', minWidth: 100 },
  { id: 'janeiro', label: 'Janeiro', minWidth: 100 },
  { id: 'fevereiro', label: 'Fevereiro', minWidth: 100 },
  { id: 'marco', label: 'Março', minWidth: 100 },
  { id: 'abril', label: 'Abril', minWidth: 100 },
  { id: 'maio', label: 'Maio', minWidth: 100 },
  { id: 'junho', label: 'Junho', minWidth: 100 },
  { id: 'julho', label: 'Julho', minWidth: 100 },
  { id: 'agosto', label: 'Agosto', minWidth: 100 },
  { id: 'setembro', label: 'Setembro', minWidth: 100 },
  { id: 'outubro', label: 'Outubro', minWidth: 100 },
  { id: 'novembro', label: 'Novembro', minWidth: 100 },
  { id: 'dezembro', label: 'Dezembro', minWidth: 100 },
];

const AnoFiscal = () => {
  const backendService = new BackendService();
  const history = useHistory();
  const [rows, setRows]: any[] = useState([]);
  const [currentFiscalYear, setCurrentFiscalYear]: any = useState({ fiscalYear: '', monthData: [{}] });
  const [fiscalYear, setFiscalYear] = useState(new Date().getFullYear()); //TODO - USE THIS TO CHANGE THE YEAR SELECTED

  useEffect(() => {
    getDataList();
    getCurrentFiscalYear();
  }, [])

  async function getDataList() {
    const getDataList: any[] = await backendService.getAllFiscalYear();
    const dataGrouped = groupDataByFiscalYear(getDataList);
    const fiscalYearsList = createListRows(dataGrouped);
    setRows(fiscalYearsList);
  }

  async function getCurrentFiscalYear() {
    const year = fiscalYear
    const currentFiscalYear: any[] = await backendService.getFiscalYearByYear(year);
    setCurrentFiscalYear(currentFiscalYear);
  }

  function createRows() {
    if (!rows) {
      return (<div></div>)
    }

    return (rows?.map(row => {
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.anoFiscal}>
          {columns.map(column => {
            const value = row[column.id];
            return (
              <TableCell key={column.id}>
                {!['anoFiscal'].includes(column.id)
                  ? currencyFormatter.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }));
  }

  function createTableContent() {
    const monthsData = currentFiscalYear.monthData;

    if (!monthsData) {
      return (<div></div>)
    }

    return (
      monthsData.map((item, index) => {
        return (
          <Box flexBasis={{ xs: '100%', sm: '50%', md: '20%' }} p={1} key={index}>
            <label className="custom-label">
              {item.monthName}
              <br />
              <input
                className="custom-input"
                type="text"
                value={currencyFormatter.format(item.value)}
                disabled
                style={{ width: '100%' }}
              />
            </label>
          </Box>
        )
      })
    )
  }

  return (
    <div className="main">
      <div className="header">
        <h3>Ano Fiscal</h3>
        <div className="new-button">
          <button className="button-active" onClick={() => history.push('/anofiscal/add')}>
            NOVO ANO FISCAL
          </button>
          <button className="button-active" onClick={() => history.push({
            pathname: '/anofiscal/edit',
            state: { fiscalYear }
          })}>
            <FaRegEdit />
          </button>
        </div>
      </div>

      <div className="table-content">
        <Box display="flex" flexWrap="wrap">
          <Box width={120} p={1}>
            <label className="custom-label">
              Ano Fiscal
              <br />
              <input className="custom-input" type="text" value={currentFiscalYear?.fiscalYear || ''} disabled />
            </label>
          </Box>
          <Box width={200} p={1}>
            <label className="custom-label">
              Início
              <br />
              <input className="custom-input" type="text" value="2023-01-01" disabled />
            </label>
          </Box>
          <Box width={200} p={1}>
            <label className="custom-label">
              Fim
              <br />
              <input className="custom-input" type="text" value="2023-12-31" disabled />
            </label>
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {createTableContent()}
        </Box>

        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {createRows()}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default AnoFiscal;
