import IAuthData from '../../interfaces/IAuthData';
import { ILoginResponse, ILogoutResponse } from '../../interfaces/serviceResponses/ILoginResponse';
import http, { getErrorMessage } from '../http';
import { setAccessToken, setLocalUser, setTokenExpire, setCustomer, getAccessToken } from '../../services/localStorage';

export const login = (data: IAuthData): Promise<ILoginResponse> => {
  return new Promise<ILoginResponse>(async (resolve, reject) => {
    try {
      const response = await http().post(`/api/v1/auth/token`, data);
      if (response.data.errors && response.data.errors.length > 0) {
        throw new Error(getErrorMessage(response.data));
      }
      const customers = response.data.data[0].customers.map(customer => ({
        customer_id: customer.customer_id,
        customer_name: customer.name,
      }));
      setCustomer(customers);
      setAccessToken(response.data.data[0].firebase_id);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const logout = (accessToken: string): Promise<ILogoutResponse> => {
  return new Promise<ILogoutResponse>(async (resolve, reject) => {
    try {
      const looker_access_token = localStorage.getItem('looker_access_token');
      const response = await http().post(`/api/v1/auth/signout`, {
        firebase_token: getAccessToken(),
      });
      if (response.data.errors && response.data.errors.length > 0) {
        throw new Error(getErrorMessage(response.data));
      }
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
