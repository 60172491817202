import React, { useEffect, useState, useContext } from 'react';
import firebase from 'firebase/app';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider, IAppState } from './common/AppContext';
import Routes from './Routes';
import { getAccessToken } from './services/localStorage';
import { initialAppState } from './common/AppContext';
import AuthMonitor from './common/AuthMonitor';
import { AppContext } from './common/AppContext';
declare global {
  interface Window {
    _env_: {
      API_URL: string;
      FIREBASE_DOMAIN: string;
      FIREBASE_API_KEY: string;
    };
    firebaseInstance?: firebase.app.App;
  }
}

const App = (): JSX.Element => {
  const [appLoading, setAppLoading] = useState(true);
  const [myAppState, setAppState] = useState<IAppState>(initialAppState);
  const { appState, appLogin, appLogout } = useContext(AppContext);

  const checkLogin = async () => {
    setAppLoading(true);
    const accessToken = getAccessToken();
    if (accessToken) {
      try {
        // setAppState({
        //   ...initialAppState,
        //   authState: {
        //     ...initialAppState.authState,
        //     isAuth: true,
        //     accessToken,
        //   },
        // });
        appLogin(accessToken);
      } catch (error) {
        console.error(error);
      }
    }

    setAppLoading(false);
  };

  useEffect(() => {
    checkLogin();
  }, []);

  if (appLoading) {
    return (
      <main>
        <div className="overlay-loading" />
      </main>
    );
  }
  return (
    <AppProvider initialState={myAppState}>
      <AuthMonitor />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
