import React, { useContext } from 'react';
import { Switch, Route } from 'react-router';
import pages from './pages';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import NotFound from '../pages/NotFound';
import { AppContext } from '../common/AppContext';
import Header from '../common/Header';

const Routes = (): JSX.Element => {
  const { appState } = useContext(AppContext);

  return (
    <>
      {appState.authState.isAuth && <Header />}
      <Switch>
        {pages.map((route, i) => {
          if (route.auth) {
            return <PrivateRoute key={i} {...route} />;
          }
          return <PublicRoute key={i} {...route} />;
        })}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
